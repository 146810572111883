/**
 * @generated SignedSource<<de173573cb370c3849207dc66683f1f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersSanitizeAuthorTypesArticle$data = {
  readonly authors: ReadonlyArray<{
    readonly types: ReadonlyArray<string | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersSanitizeAuthorTypesArticle";
};
export type helpersSanitizeAuthorTypesArticle$key = {
  readonly " $data"?: helpersSanitizeAuthorTypesArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersSanitizeAuthorTypesArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersSanitizeAuthorTypesArticle"
};

(node as any).hash = "5b66a15615b257235adff1ad1db8a8b1";

export default node;
