/**
 * @generated SignedSource<<ded0ff7a616681237ceae4b30a532865>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsPlusArticle$data = {
  readonly paywallTypes?: ReadonlyArray<{
    readonly entityUuid: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsPlusArticle";
};
export type helpersCheckIsPlusArticle$key = {
  readonly " $data"?: helpersCheckIsPlusArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPlusArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsPlusArticle"
};

(node as any).hash = "752dbf5650b9f400359c10fa730311f5";

export default node;
