import type { section } from "shared/data";

import type { DefaultComponentsProvider } from ".";

export enum ArticleRenderType {
  Generic = "generic",
  Infographic = "infographic",
  InfographicLongRead = "infographic-long-read",
  Live = "live",
  LongRead = "long-read",
  MorningStudio = "morning-studio",
  Newsletter = "newsletter",
  PhotoEssay = "photo-essay",
  Plus = "plus",
  Posties = "posties",
  PostMagazine = "post-magazine",
  Series = "series",
  Style = "style",
}

export type ArticleRenderChildProps = {
  isInlinePaywallDomChanged?: boolean;
  isShowInlinePaywall?: boolean;
  provider: DefaultComponentsProvider;
};

export type MagazineVariant =
  | typeof section.postMagazine.entityId
  | typeof section.thisWeekInAsia.entityId;
