/**
 * @generated SignedSource<<7e4fd4de8a4946e9249932f4c83a4f59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckArticleRenderTypeArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckCanShowInPlusPlatformContent" | "helpersCheckIsPlusArticle" | "helpersCheckIsPostMagazineArticle" | "helpersCheckIsPostiesArticle" | "helpersCheckIsStyleArticle" | "helpersGetArticleTypeArticle">;
  readonly " $fragmentType": "helpersCheckArticleRenderTypeArticle";
};
export type helpersCheckArticleRenderTypeArticle$key = {
  readonly " $data"?: helpersCheckArticleRenderTypeArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckArticleRenderTypeArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckArticleRenderTypeArticle"
};

(node as any).hash = "3ca4325286a22b72e69eaafd4fbc42af";

export default node;
