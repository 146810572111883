/**
 * @generated SignedSource<<354ad0984290d2778a52a06bd2ed25c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsMainTopicContent$data = {
  readonly topics: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsMainTopicContent";
};
export type helpersCheckIsMainTopicContent$key = {
  readonly " $data"?: helpersCheckIsMainTopicContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsMainTopicContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsMainTopicContent"
};

(node as any).hash = "a4b1f8649aa0caef4fee0a1c473e2f19";

export default node;
