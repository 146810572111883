import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.v2.js";
import "core-js/modules/esnext.set.union.js";
export const article = {
  articles: {
    announcement: {
      entityId: "3119450"
    }
  },
  flag: {
    video: "VIDEO"
  },
  types: {
    analysis: {
      entityId: "328387"
    },
    blogs: {
      entityId: "784"
    },
    chinaFutureTechBriefing: {
      entityId: "520178",
      entityUuid: "789e88eb-148d-42e6-8d33-69e62f3a3bc5",
      name: "China Future Tech Briefing"
    },
    column: {
      entityId: "783",
      entityUuid: "012d7708-2959-4b2b-9031-23e3d025a08d"
    },
    dailyPulseBriefing: {
      entityId: "517498",
      entityUuid: "09ecd76b-0271-467d-be05-c228b9a8ef47",
      name: "Daily Pulse"
    },
    debate: {
      entityId: "328051"
    },
    editorial: {
      entityId: "500285"
    },
    explainer: {
      entityId: "328386",
      entityUuid: "a44f9c2e-7df3-4b76-bc42-9f60aefc7344",
      name: "Explainer"
    },
    factCheck: {
      entityId: "328388"
    },
    factSheet: {
      entityId: "517499",
      entityUuid: "8b450c30-742e-4668-978c-1868587f603f",
      name: "Fact Sheet"
    },
    infographic: {
      entityId: "27557",
      name: "Infographic"
    },
    infographicLongRead: {
      entityId: "509798",
      name: "Infographic|Long Read"
    },
    letters: {
      entityId: "329083",
      entityUuid: "c8774510-c0e0-4117-99a5-48c444acc219"
    },
    live: {
      entityId: "27556",
      entityUuid: "c95a7a01-e8fe-4d06-b8fd-248824cb9c5f",
      name: "Live"
    },
    longRead: {
      entityId: "328194",
      name: "Long Read"
    },
    newsletter: {
      entityId: "507042",
      name: "Newsletter"
    },
    obituary: {
      entityId: "328389"
    },
    openQuestionsSeries: {
      entityId: "506855",
      entityUuid: "de8692bb-3604-4cae-8557-c96066c12802",
      name: "Open Questions Series"
    },
    opinion: {
      entityId: "328587",
      entityUuid: "175d77bc-2793-43ce-aa75-17d537ba2e51",
      name: "Opinion"
    },
    photoEssay: {
      entityId: "328710",
      name: "Photo essay"
    },
    podcast: {
      entityId: "328588"
    },
    profile: {
      entityId: "509368"
    },
    qa: {
      entityId: "16560",
      name: "Q&A"
    },
    quickDigest: {
      entityId: "517593",
      entityUuid: "6dbdfc18-3199-4789-8a62-adfd9d292cf1",
      name: "Quick Digest"
    },
    recap: {
      entityId: "519778",
      entityUuid: "baf8deb2-2956-4a83-88e3-4e94fb24e00b",
      name: "Recap"
    },
    review: {
      entityId: "318341",
      name: "Review"
    },
    series: {
      entityId: "506855",
      entityUuid: "35e8a7ae-0200-4a16-b195-195d825e2c5c",
      name: "Series"
    },
    seriesDefaultLabel: {
      entityId: "509605"
    },
    studio: {
      entityId: "328367"
    },
    studioMorningStudioLogoOnly: {
      entityId: "503583"
    },
    studioScmpLogoOnly: {
      entityId: "503582"
    }
  }
};
export const starlingArticleEntityIds = new Set(["3160321", "3160333", "3160466", "3160469", "3160475", "3176982"]);
export const acknowledgementGateArticleIds = new Set(["1780152", "1781385", "1782752", "1783122", "1784494", "1785202", "1786367", "1787941", "1788985", "1815689", "1840168", "1840737", "1854626", "1855229", "1857242", "1862338", "1863178", "1863522", "1944555", "2057808"]);
export const articleSponsorType = new Set(["business_reports", "country_reports", "events", "native", "presented", "special_reports", "specialist_publications"]);