/**
 * @generated SignedSource<<65803b02b1166a8b5ff61c32f568a4aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsPostMagazineArticle$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsPostMagazineArticle";
};
export type helpersCheckIsPostMagazineArticle$key = {
  readonly " $data"?: helpersCheckIsPostMagazineArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPostMagazineArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsPostMagazineArticle"
};

(node as any).hash = "3c50ccf3cc38066d02c13b960dc547e3";

export default node;
