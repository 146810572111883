/**
 * @generated SignedSource<<9ebe877766c65f9d44c29f1d7b23d3fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetArticleTypeNameArticle$data = {
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersGetArticleTypeNameArticle";
};
export type helpersGetArticleTypeNameArticle$key = {
  readonly " $data"?: helpersGetArticleTypeNameArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetArticleTypeNameArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetArticleTypeNameArticle"
};

(node as any).hash = "de3e4e4f13f69e4b7226e9a561c8e187";

export default node;
