/**
 * @generated SignedSource<<92abaa29b102e4b2368e4fd69d16b9d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersUseContentSponsorContent$data = {
  readonly sponsorType?: string | null | undefined;
  readonly topics: ReadonlyArray<{
    readonly entityId: string;
    readonly sponsor: {
      readonly name: string | null | undefined;
      readonly type: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"sponsorTagSponsor">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsMainTopicContent">;
  readonly " $fragmentType": "helpersUseContentSponsorContent";
};
export type helpersUseContentSponsorContent$key = {
  readonly " $data"?: helpersUseContentSponsorContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersUseContentSponsorContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersUseContentSponsorContent"
};

(node as any).hash = "ebbeb4be8563e36d49558b9da71373ac";

export default node;
