/**
 * @generated SignedSource<<ce4dc64f77f6974c48c3e2e4c144d98b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckArticleIsInSectionsArticle$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckArticleIsInSectionsArticle";
};
export type helpersCheckArticleIsInSectionsArticle$key = {
  readonly " $data"?: helpersCheckArticleIsInSectionsArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckArticleIsInSectionsArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckArticleIsInSectionsArticle"
};

(node as any).hash = "777bccadca2d0ad04044433b0da33ef5";

export default node;
