/**
 * @generated SignedSource<<7c017d6a69a6f0d51a491554c46cb512>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetContentTypeNameContent$data = {
  readonly __typename: "Article";
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetArticleTypeNameArticle">;
  readonly " $fragmentType": "helpersGetContentTypeNameContent";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "helpersGetContentTypeNameContent";
};
export type helpersGetContentTypeNameContent$key = {
  readonly " $data"?: helpersGetContentTypeNameContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetContentTypeNameContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetContentTypeNameContent"
};

(node as any).hash = "01c8f74ce70e22a98bf90c2bee069aec";

export default node;
