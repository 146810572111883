/**
 * @generated SignedSource<<1f63e193ee53622194f7f2cf707a617f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckCanShowInPlusPlatformContent$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckArticleIsInSectionsArticle" | "helpersCheckIsPlusArticle" | "helpersCheckIsStyleArticle" | "helpersGetArticleTypeArticle" | "helpersUseContentSponsorContent">;
  readonly " $fragmentType": "helpersCheckCanShowInPlusPlatformContent";
};
export type helpersCheckCanShowInPlusPlatformContent$key = {
  readonly " $data"?: helpersCheckCanShowInPlusPlatformContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckCanShowInPlusPlatformContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckCanShowInPlusPlatformContent"
};

(node as any).hash = "e8848cb7536bfe1675b3b25c8d3280ab";

export default node;
