/**
 * @generated SignedSource<<67ffd670b6985bbe291ec65f63b012e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetArticleTypeArticle$data = {
  readonly types?: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersGetArticleTypeArticle";
};
export type helpersGetArticleTypeArticle$key = {
  readonly " $data"?: helpersGetArticleTypeArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetArticleTypeArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetArticleTypeArticle"
};

(node as any).hash = "7639a5fd3b7005a2a24993cc17bd11eb";

export default node;
